import React, { useState } from "react"
import * as Yup from "yup"
import RcLoadingModal from "../page-components/modal/rcLoadingModal"
import * as styles from "./single/EventAttendeeForm.module.scss"
import { Formik } from "formik"
import axios from "axios"
import { getStrapiUrl } from "../helper/uri"
import { toast } from 'react-toastify';
import RcFormErrors from "../page-components/helper/rcFormErrors"
import RcButton from "../components/button/rcButton"

const ImmobilienDetailForm = ( { immo, className }) => {

  const [ sent, setSent ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const formSchema = Yup.object().shape(
    {
      mail: Yup.string().email("Ungültige Mail-Adresse").required("E-Mail Adresse notwendig"),
      firstname: Yup.string().required("Vorname notwendig"),
      lastname: Yup.string().required("Nachname notwendig"),
      address: Yup.string().required("Ihre Adresse ist notwendig"),
      postcode: Yup.string().required("Ihre Postleitzahl ist notwendig"),
      city: Yup.string().required("Ihre Stadt ist notwendig")
    }
  );

  return <>

    <RcLoadingModal isOpen={loading} />

      <div className={"row"}>
        <div className={styles.formWrapper + " col-12 px-4 py-4 " + className}>

          <h2>Immobilie anfragen</h2>

          {!sent &&

          <Formik
            initialValues={
              {
                mail: '',
                firstname: '',
                lastname: '',
                address: '',
                postcode: '',
                city: ''
              }
            }

            validationSchema={formSchema}

            onSubmit={
              async (values) => {
                setLoading(true);

                try {
                  await axios.post(getStrapiUrl() + "/immo-inquiry",
                    {
                      id: immo.strapiId,
                      ...values
                    }
                  );
                   setSent(true);
                } catch(err) {
                  toast("Es ist ein unbekannter Fehler passiert.");
                } finally {
                  setLoading(false);
                }

              }
            }
          >
            {
              ({
                 values,
                 handleChange,
                 handleBlur,
                 handleSubmit,

                 touched,
                 errors
               }) => {
                return <form className={"rc-form row"}>

                  <div className="col-lg-6 col-12">

                    <div className="rc-form-control">

                      <label htmlFor="mail">E-Mail*</label>

                      <input name="mail"
                             id="mail"
                             type="email"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             value={values.mail}/>

                    </div>

                    <div className="rc-form-control">

                      <label htmlFor="firstname">Vorname*</label>

                      <input name="firstname"
                             id="firstname"
                             type="text"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             value={values.firstname}/>

                    </div>

                    <div className="rc-form-control">

                      <label htmlFor="lastname">Nachname*</label>

                      <input name="lastname"
                             id="lastname"
                             type="text"
                             onChange={handleChange}
                             onBlur={handleBlur}
                             value={values.lastname}/>

                    </div>

                  </div>

                  <div className="col-lg-6 col-12">

                  <div className="rc-form-control">

                    <label htmlFor="address">Adresse*</label>

                    <input name="address"
                           id="address"
                           type="text"
                           onChange={handleChange}
                           onBlur={handleBlur}
                           value={values.address}/>

                  </div>

                  <div className="rc-form-control">

                    <label htmlFor="postcode">Postleitzahl*</label>

                    <input name="postcode"
                           id="postcode"
                           type="text"
                           onChange={handleChange}
                           onBlur={handleBlur}
                           value={values.postcode}/>

                  </div>

                  <div className="rc-form-control">

                    <label htmlFor="city">Stadt*</label>

                    <input name="city"
                           id="city"
                           type="text"
                           onChange={handleChange}
                           onBlur={handleBlur}
                           value={values.city}/>

                  </div>

                  <RcFormErrors touched={touched} errors={errors} className={"mb-2"} />


                  <RcButton onClick={handleSubmit} className={"d-inline-block mt-2 mb-4"}>
                    Anfragen
                  </RcButton>

                  </div>

                </form>
              }
            }
          </Formik>

          }

          {
            sent &&
              <div className={"mt-5"}>
                <h3>Vielen Dank</h3>
                <p>Ihre Immobilien Anfrage war erfolgreich.</p>
              </div>
          }

        </div>
      </div>
  </>;

};

export default ImmobilienDetailForm;
