// extracted by mini-css-extract-plugin
export var headerImage = "immobilienDetail-module--headerImage--251_g";
export var freitext = "immobilienDetail-module--freitext--1p946";
export var impressions = "immobilienDetail-module--impressions--1JwxF";
export var sliderCard = "immobilienDetail-module--sliderCard--3T-hs";
export var propertyTab = "immobilienDetail-module--propertyTab--2Pndl";
export var osmBg = "immobilienDetail-module--osmBg--2Eew6";
export var paginationCustom = "immobilienDetail-module--paginationCustom--3aiT1";
export var headerSlide = "immobilienDetail-module--headerSlide--3aeLX";
export var currentImageIndex = "immobilienDetail-module--currentImageIndex--1AnFr";
export var energieTable = "immobilienDetail-module--energieTable--3hFPl";
export var swiperTop = "immobilienDetail-module--swiperTop--1zYY4";
export var contactAvatar = "immobilienDetail-module--contactAvatar--2bVc4";
export var contactWrapper = "immobilienDetail-module--contactWrapper--2iqNX";
export var swiperWrapper = "immobilienDetail-module--swiperWrapper--W4L0e";