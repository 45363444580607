import React from 'react';
import * as styles from './rcImmoProperty.module.scss';
import RcCurrency from "../format/rcCurrrency"
import Rc2Digits from "../format/rc2Digits"

const RcImmoProperty = ( { className = '', value, label, valueType = 'normal' }) => {

  if (!value) {
    return null;
  }

  return <div className={styles.property + " " + className}>
    <div className={styles.label}>
      { label }
    </div>

    { valueType === 'normal' && value }
    { valueType === 'currency' && <RcCurrency value={value} /> }
    { valueType === 'int' && Number.parseInt(value) }
    { valueType === 'infoText' && <div className={styles.infoText}>{ value }</div> }
    { valueType === 'area' && <div className={styles.infoText}> <Rc2Digits value={value} /> m²</div> }
  </div>

}

export default RcImmoProperty;
