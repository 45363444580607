import React, { useState } from "react"
import * as styles from './immobilienDetail.module.scss';
import "./immobilienDetail.scss";
import { Link } from "gatsby";

import Layout from "../page-components/layout"
import { graphql } from "gatsby"
import RcImmoPrice, { getImmoPrice } from "../helper-components/immo/rcImmoPrice"
import RcImmoProperty from "../helper-components/immo/rcImmoProperty"
import RcImmoArea from "../helper-components/immo/rcImmoArea"
import { GatsbyImage } from "gatsby-plugin-image"

import ChevronLeftSVG from "../icons/fontawesome/light/chevron-left.svg";
import PDFFileSVG from "../icons/fontawesome/light/file-pdf.svg";


import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react"
import RcCard from "../components/card/rcCard"
import { getHeizung, getObjektart } from "../helper/immo"
import { shuffleArray } from "../helper/array"
import ImmobilienDetailForm from "./immobilienDetailForm"
import nl2br from "nl2br"

SwiperCore.use([Navigation, Pagination ]);

const ImmobileDetailTemplate = ({ data } ) => {

  const kontaktpersonMail = data.immo.kontaktperson?.email_direkt?.toLowerCase();
  const immoContact = data.allStrapiImmoContacts.nodes.find( f => f.mail.toLowerCase() === kontaktpersonMail);

  const [ currentIndex, setCurrentIndex ] = useState(1);

  const immo = data.immo;
  const images = immo.Anhang.filter(
    a => {
      if (!a.daten?.localFile?.childImageSharp) {
        return false;
      }

      if (a.gruppe === "GRUNDRISS") {
        return false;
      }

      return true;
    }
  );

  const propCSS = "text-white mt-3";

  const relatedImmos = shuffleArray(data.allStrapiImmos.nodes)
                          .filter( (i) => getObjektart(immo) === getObjektart(i) )
                          .filter( (i) => i.id !== immo.id)
                          .filter( (immo, index) => index < 4);

  if (relatedImmos.length !== 4) {
    relatedImmos.push(  ...shuffleArray(data.allStrapiImmos.nodes).slice(0, 4 -  relatedImmos.length) );
  }

  const heizung = getHeizung(immo);

  return <>

  <Layout>

    <div className="container h-100">

      <div className="row bg-black align-items-center ">
        <div className={"col-12 col-lg-8 " + styles.swiperTop}>
          <Swiper
            loop={true}
            navigation
            onSlideChange={
              (swiper) => {
                setCurrentIndex(swiper.activeIndex);
              }
            }
            className={styles.swiperWrapper}
            slidesPerView={1} >

            { images.map(
              a => {
                return (
                  <SwiperSlide className={"p-relative "}>
                    <GatsbyImage
                      image={a.daten.localFile.childImageSharp.gatsbyImageData}
                      className={"h-100 " + styles.headerSlide} />

                    {images && images.length > 0 &&
                    <p className={styles.currentImageIndex}>Bild {(currentIndex % (images.length)) === 0 ? images.length : (currentIndex % images.length) } von {images.length}</p>
                    }


                  </SwiperSlide>
                );
              }
            )}
          </Swiper>

        </div>

        <div className="col-12 col-lg-4">
          <div className="p-3">

            <RcImmoProperty value={immo.objektnr_extern} label={"Objektnummer"} className={propCSS + " mt-0"} />

            {(immo.flaechen?.gesamtflaeche || immo.flaechen?.lagerflaeche) &&
              <RcImmoProperty value={<RcImmoArea immo={immo} />} label={"Fläche"} className={propCSS} />
            }

            {getImmoPrice(immo).price &&
              <RcImmoProperty value={<RcImmoPrice subInfoPlacement={'bottom'} immo={immo} showFull={true} />}
                              label={"Preis"} className={propCSS} />
            }

            <RcImmoProperty value={ immo?.flaechen?.anzahl_zimmer } valueType={'int'} label={"Zimmer"} className={propCSS} />

            <RcImmoProperty value={
                                  <>
                                    { immo?.geo.plz } { immo?.geo.ort }
                                  </>
                                }
                            label={"Adresse"}
                            className={propCSS} />


            <div className={"mt-5"}>
              <a    target={"_blank"}
                    className={"text-white d-flex align-items-center"}
                    href={"/immobilien-expose/kurz-expose/?id=" + immo.strapiId}>

                <PDFFileSVG className={"mr-1 svgFill"} />
                Download Kurz Expose
              </a>
            </div>

          </div>
        </div>
      </div>

      <div className={"row contentCaption"}>
        <div className="col-12 py-4 p-lg-5">

          <Link to={"/immobilien"}>
            <div className="d-flex text-white align-items-center">
              <ChevronLeftSVG className={"mr-2 text-white svgFill"} />
              Zurück
            </div>
          </Link>

          <h4>{ immo?.freitexte.objekttitel }</h4>
        </div>
      </div>

      <div className="row bg-white">
        <div className="col-12 col-lg-8">
          <div className="px-lg-3 py-lg-5 py-3">

            <div className={styles.freitext}
              dangerouslySetInnerHTML={
              {
                __html: nl2br(immo?.freitexte.objektbeschreibung)
              }
            }/>

            {immo?.freitexte.lage &&
            <div className={styles.freitext + " mt-5"}
                 dangerouslySetInnerHTML={
                   {
                     __html: nl2br(immo?.freitexte.lage)
                   }
                 }/>
            }

            {immo?.zustand_angaben?.energiepass &&
            <>
              <h4 className={"mt-5 mt-xl-6 mb-1"}>Energiepass</h4>

              <table className={"table mt-2 " + styles.energieTable}>

                {immo.zustand_angaben.energiepass.epart &&
                  <tr>
                    <td>
                      <span className={"opacity-75"}>Energieausweistyp</span>
                    </td>

                    <td>
                      {immo.zustand_angaben.energiepass.epart.slice(0, 1).toUpperCase()}
                      {immo.zustand_angaben.energiepass.epart.slice(1).toLowerCase()}
                    </td>
                  </tr>
                }


                {immo.zustand_angaben.energiepass.energieverbrauchkennwert &&
                <tr>
                  <td>
                    <span className={"opacity-75"}>Energieverbrauchkennwert</span>
                  </td>

                  <td>{immo.zustand_angaben.energiepass.energieverbrauchkennwert}</td>
                </tr>
                }


                {immo.zustand_angaben.energiepass.hwbwert &&
                <tr>
                  <td>
                    <span className={"opacity-75"}>HWB-Wert: </span>
                  </td>

                  <td>
                    {immo.zustand_angaben.energiepass.hwbwert} kWh/(m²a)
                  </td>
                </tr>
                }


                {immo.zustand_angaben.energiepass.hwbklasse &&
                  <tr>
                    <td>
                      <span className={"opacity-75"}>HWB-Klasse: </span>
                    </td>

                    <td>
                      {immo.zustand_angaben.energiepass.hwbklasse}
                    </td>
                  </tr>
                }

                { heizung &&
                <tr>
                  <td>
                    <span className={"opacity-75"}>Heizung: </span>
                  </td>

                  <td>
                    {heizung}
                  </td>
                </tr>

                }

              </table>

            </>}

          </div>
        </div>

        <div className={"col-12 col-lg-4 " + styles.propertyTab}>

          <div className="p-4">
            <h5>Preise</h5>

            <RcImmoProperty valueType={"currency"} value={immo.preise?.nettokaltmiete} label={"Kalt-Miete"} />
            <RcImmoProperty valueType={"currency"} value={immo.preise?.kaltmiete} label={"Kalt-Miete"} />
            <RcImmoProperty valueType={"currency"} value={immo.preise?.nebenkosten} label={"Nebenkosten"} />
            <RcImmoProperty valueType={"currency"} value={immo.preise?.gesamtmietenetto} label={"Gesamt Miete Netto"} />
            <RcImmoProperty valueType={"currency"} value={immo.preise?.gesamtmietebrutto} label={"Gesamt Miete inkl. Ust"} />
            <RcImmoProperty valueType={"currency"} value={immo.preise?.gesamtbelastungbrutto} label={"Summe Belastung inkl. Ust"} />
            <RcImmoProperty valueType={"currency"} value={immo.preise?.summemietenetto} label={"Summe Miete Netto"} />
            {/*<RcImmoProperty valueType={"currency"} value={immo.preise?.mietpreis_pro_qm} label={"Mietpreis pro m²"} />*/}
            {/*<RcImmoProperty valueType={"currency"} value={immo.preise?.kaufpreis_pro_qm} label={"Kaufpreis pro m²"} />*/}
            {/*<RcImmoProperty valueType={"currency"} value={immo.preise?.betriebskostennetto} label={"Betriebskosten Netto"} />*/}
            <RcImmoProperty valueType={"currency"} value={immo.preise?.monatlichekostenbrutto} label={"monatliche Kosten inkl. Ust"} />
            <RcImmoProperty valueType={"currency"} value={immo.preise?.provisionbrutto} label={"Provision inkl. Ust"} />


            <RcImmoProperty valueType={"infoText"} value={immo.preise?.freitext_preis} label={"Info"} />


            <div>
              <h5 className="mt-5">Flächen</h5>

              {immo.flaechen?.nutzflaeche != immo.flaechen?.gesamtflaeche &&
              <RcImmoProperty valueType={"area"} value={immo.flaechen?.nutzflaeche} label={"Nutzfläche"}/>
              }

              <RcImmoProperty valueType={"area"} value={immo.flaechen?.gesamtflaeche} label={"Gesamtfläche"} />
              <RcImmoProperty valueType={"area"} value={immo.flaechen?.grundstuecksflaeche} label={"Grundstücksfläche"} />
            </div>
            {/*<RcImmoProperty valueType={"int"} value={immo.flaechen?.anzahl_stellplaetze} label={"Anzahl Stellplätze"} />*/}

          </div>

        </div>
      </div>

      { immoContact &&
      <div className={"row py-5 " + styles.contactWrapper}>
        <div className="col-12">

          <div className="d-flex">

            <GatsbyImage className={styles.contactAvatar}
                         alt={"Immobilien Ansprechperson"} image={immoContact.avatar.localFile.childImageSharp.gatsbyImageData} />

            <div className={"pl-3"}>
              <h4 className={"mb-3"}>Ihre Ansprechperson</h4>

              <p>
                <strong>
                  { immoContact.fullname }
                </strong>
              </p>

              <p>
                <a href={"mailto:" + immoContact.mail}>
                { immoContact.mail }
                </a>
              </p>

              <p>
                <a href={"telto:" + immoContact.phone}>
                { immoContact.phone }
                </a>
              </p>
            </div>

          </div>

        </div>
      </div>
      }

      <ImmobilienDetailForm immo={immo} />

      <div className="row bg-primary-dark">
        <div className="col-12 pt-3 pb-1">
          <h4 className={"text-white my-3 d-block"}>Weitere ähnliche Immobilien</h4>
        </div>
      </div>

      <div className="row bg-primary-dark">
        { relatedImmos.map(
          immo => {
            return <RcCard to={"/immobilien/" + immo.id}
                           style={"small"}
                           className={"mb-4"}
                           innerClassName={"h-100"}
                           caption={immo.title}
                           img={immo.Anhang?.length > 0 ? immo.Anhang[0].daten?.localFile?.childImageSharp?.gatsbyImageData : undefined} />
          }
        )}
      </div>


    </div>

  </Layout>

    </>;
};

export default ImmobileDetailTemplate;

export const query = graphql`query immoDetailQuery($id: String!) {

    allStrapiImmos(filter: {title: {ne: "EMPTY"}}) {
      nodes {
        id
        strapiId
        title
        objektnr_extern
        objektkategorie {
          objektart {
            haus {
              haustyp
            }
            buero_praxen {
              buero_typ
            }
          }
        }
        Anhang {
          gruppe
          daten {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }

  allStrapiImmoContacts {
      nodes {
        fullname
        mail
        phone
        avatar {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }


  immo: strapiImmos(id: {eq: $id}) {
    id
    strapiId
    uuid
    objektnr_extern
    isBIZ
    latitude
    longitude
    objektkategorie {
      objektart {
        haus {
          haustyp
        }
        buero_praxen {
          buero_typ
        }
      }
    }
    geo {
      plz
      ort
    }
    preise {
      nettokaltmiete
      nebenkosten
      zzg_mehrwertsteuer
      freitext_preis
      provisionspflichtig
      kaution
    }
    flaechen {
      nutzflaeche
      gesamtflaeche
      bueroflaeche
      grundstuecksflaeche
      einliegerwohnung
      wohnflaeche
      anzahl_zimmer
      anzahl_schlafzimmer
      anzahl_badezimmer
      anzahl_sep_wc
      anzahl_balkone
      balkon_terrasse_flaeche
      anzahl_terrassen
      anzahl_wohneinheiten
      kellerflaeche
      ladenflaeche
      freiflaeche
    }
    ausstattung {
      klimatisiert
      gartennutzung
      kabel_sat_tv
      barrierefrei
      swimmingpool
      dv_verkabelung
      teekueche
      wg_geeignet
      stromanschlusswert
      hallenhoehe
      befeuerung {
        FERN
        GAS
        LUFTWP
        SOLAR
      }
    }
    freitexte {
      objekttitel
      lage
      objektbeschreibung
    }
    zustand_angaben {
      energiepass {
        epart
        hwbwert
        hwbklasse
      }
    }
    kontaktperson {
      email_direkt
    }
    Anhang {
      gruppe
      daten {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.5)
          }
        }
      }
    }
  }
}
`
